<template>
  <b-overlay :show="isLoading" spinner-small rounded="sm" :variant="skin">
    <div v-for="(item, index) in config" :key="index" class="process">
      <b-avatar
        :size="size"
        :variant="item.color"
        class="circle"
        v-b-tooltip.hover.top.html="tooltip(item.tooltip)"
        :button="activeButton(item)"
        @click="actionClick(item)"
        :badge-variant="getColor(item)"
        badge-offset="-8px"
        badge-top
      >
        <feather-icon
          :icon="activeButton(item) ? 'MousePointerIcon' : item.icon"
        />
        <template v-if="showBadge(item)" #badge>
          <div class="p-50"></div>
        </template>
      </b-avatar>
      <div v-if="index + 1 < config.length" class="bar" :class="item.color" />
    </div>
  </b-overlay>
</template>

<script>
import { BAvatar, VBTooltip, BOverlay } from "bootstrap-vue";
import _trackingServices from "@/services/tracking-service";
import _recordMediaService from "@/services/audiovisual/recording-media";
import moment from "moment-timezone";
import useAppConfig from "@core/app-config/useAppConfig";
export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BAvatar,
    BOverlay,
  },
  data() {
    return {
      isLoading: false,
      isActiveFT: false,
      config: [
        {
          icon: "XIcon",
          color: "primary",
          tooltip: ["agendamento"],
        },
        {
          icon: "MoreHorizontalIcon",
          color: "primary",
          tooltip: ["operação"],
        },
        {
          icon: "MoreHorizontalIcon",
          color: "primary",
          tooltip: ["edição"],
        },
        {
          icon: "MoreHorizontalIcon",
          color: "primary",
          tooltip: ["upload"],
        },
        {
          icon: "MoreHorizontalIcon",
          color: "primary",
          tooltip: ["validação"],
        },
      ],
      tzString: Intl.DateTimeFormat().resolvedOptions().timeZone,
      possibleValidation: false,
      possibleValidationFT: false,
      uploadControlID: null,
      uploadControlTransmissionID: null
    };
  },
  props: {
    id: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: "",
    },
    validateAction: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.getRecords();
  },
  methods: {
    getRecords() {
      this.isLoading = true;
      _trackingServices
        .find([this.id])
        .then((res) => {
          if (res.content && res.content.length > 0) {
            let response = res.content[0];
            this.isActiveFT = this.isFT(response);
            this.trackingConfig(response);
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isLoading = false));
    },
    tooltip(info) {
      var text = "";
      info.forEach((element, index) => {
        if (index == 0) element = element.toUpperCase();
        text += element;
        if (index + 1 < info.length && element !== "<hr class='m-25 p-0' />")
          text += "<br />";
      });
      return text;
    },
    actionClick() {
      this.$emit("actionClick", {
        mainProduct: this.possibleValidation,
        ftProduct: this.possibleValidationFT,
        uploadControlTransmissionID: this.uploadControlTransmissionID,
        uploadControlID: this.uploadControlID
      });
    },
    trackingConfig(data) {
      this.config.forEach((step) => {
        // AGENDAMENTO
        if (step.tooltip[0] == "agendamento") {
          if (data.appointment_id) {
            if (
              data.status_appointment &&
              data.status_appointment == "Canceled"
            ) {
              step.color = "danger";
              step.tooltip.push(
                data.canceled_out_of_time
                  ? "Cancelado fora do prazo"
                  : "Cancelado",
                data.canceled_reason
                  ? `Motivo: ${data.canceled_reason}`
                  : "Motivo: Não Informado",
                this.dateFormatter(data.datetime_appointment)
              );
            } else {
              step.icon = "CheckIcon";
              step.color = "success";
              step.tooltip.push(this.dateFormatter(data.datetime_appointment));
            }
          } else {
            step.tooltip.push("Sem agendamento");
          }
        }
        // OPERAÇÃO
        if (step.tooltip[0] == "operação") {
          if (data.appointment_id && data.status_appointment != "Canceled") {
            if (!data.finalized) {
              step.icon = "ZapIcon";
              step.color = "warning";
              if (data.datetime_appointment) {
                if (this.appointmentOnHold(data.datetime_appointment)) {
                  if (data.start_time_studio_operator) {
                    step.tooltip.push(
                      `Em progresso: ${this.clockRunning(
                        data,
                        "start_time_studio_operator"
                      )}`
                    );
                  } else {
                    step.tooltip.push("Demanda aguardando operação");
                  }
                } else {
                  step.tooltip.push(`Início: ${data.datetime_appointment}`);
                }
              }
            } else {
              step.icon = "CheckIcon";
              step.color = "success";
              step.tooltip.push(
                "Finalizado",
                this.dateUTCFormatter(data.finish_time_studio_operator)
              );
            }
          } else if (!data.appointment_id) {
            step.icon = "XIcon";
            step.tooltip.push("Sem Operação");
          }
        }
        // EDIÇÃO
        if (step.tooltip[0] == "edição") {
          if (data.finalized) {
            let status_name = data.status_name
              ? data.status_name.toLowerCase()
              : "pendente";
            step.tooltip.push(
              status_name.charAt(0).toUpperCase() + status_name.slice(1)
            );
            if (data.edition_id) {
              if (status_name == "finalizado") {
                step.icon = "CheckIcon";
                step.color = "success";
                step.tooltip.push(this.dateFormatter(data.datetime_edition));
              } else if (
                status_name.indexOf("finalizado") != -1 &&
                !data.finalized
              ) {
                this.config[1].icon = "ZapIcon";
                this.config[1].color = "warning";
                step.icon = "ZapOffIcon";
                step.color = "warning";
              } else if (status_name == "cancelado") {
                step.icon = "ZapOffIcon";
                step.color = "danger";
              } else {
                step.icon = "ZapIcon";
                step.color = "warning";
                step.tooltip.push(
                  `Em Progresso: ${this.clockRunning(data, "finalized_at")}`
                );
              }
            } else {
              step.icon = "ZapIcon";
              step.color = "warning";
            }
          }
          if (this.isActiveFT) {
            step.tooltip.push(
              "<hr class='m-25 p-0' />",
              "EDIÇÃO F.T."
            );
            if (data.edition_transmission_id && data.status_transmission_name) {
              step.status_name = data.status_transmission_name;
              step.tooltip.push(
                data.status_transmission_name,
                data.datetime_transmission_edition
                  ? this.dateFormatter(data.datetime_transmission_edition)
                  : `Em Progresso: ${this.clockRunning(data, "finalized_at")}`
              );
            } else {
              step.tooltip.push("Pendente");
              step.status_name = "em progresso";
            }
          }
        }
        // UPLOAD
        if (step.tooltip[0] == "upload") {
          if (data.edition_id) {
            let status_name = data.status_name
              ? data.status_name.toLowerCase()
              : null;
            if (status_name == "finalizado" && !data.datetime_uploaded) {
              step.icon = "ZapIcon";
              step.color = "warning";
              step.tooltip.push(
                `Em Progresso: ${this.clockRunning(data, "datetime_edition")}`
              );
            } else if (data.datetime_uploaded) {
              step.icon = "CheckIcon";
              step.color = "success";
              step.tooltip.push(
                "Finalizado",
                this.dateUTCFormatter(data.datetime_uploaded)
              );
            }
          }
          if (this.isActiveFT) {
            let edition_status_name = data.status_transmission_name
              ? data.status_transmission_name.toLowerCase()
              : null;
            step.status_name =
              edition_status_name != "finalizado"
                ? "Pendente"
                : data.datetime_transmission_uploaded
                ? "Finalizado"
                : "Em Progresso";
            step.tooltip.push("<hr class='m-25 p-0' />", "UPLOAD F.T.");
            if (data.datetime_transmission_uploaded) {
              step.tooltip.push(
                "Finalizado",
                this.dateFormatter(data.datetime_transmission_uploaded)
              );
            } else if (data.datetime_transmission_edition && edition_status_name == "finalizado") {
              step.tooltip.push(
                `Em Progresso: ${this.clockRunning(
                  data,
                  "datetime_transmission_edition"
                )}`
              );
            }
          }
        }
        // VALIDAÇÂO
        if (step.tooltip[0] == "validação") {
          if (data.datetime_uploaded && !data.datetime_validated) {
            step.icon = "ZapIcon";
            step.color = this.validateAction ? "info" : "warning";
            step.tooltip.push(
              `Em Progresso: ${this.clockRunning(data, "datetime_uploaded")}`
            );
            this.possibleValidation = true;
            this.uploadControlID = data.recordings_upload_control_id;
          } else if (data.datetime_validated) {
            step.icon = "CheckIcon";
            step.color = "success";
            step.tooltip.push(
              "Finalizado",
              this.dateUTCFormatter(data.datetime_validated)
            );
          }
          if (this.isActiveFT) {
            step.status_name = !data.datetime_transmission_uploaded
              ? "Pendente"
              : data.datetime_transmission_validated
              ? "Finalizado"
              : "Em Progresso";
            step.tooltip.push("<hr class='m-25 p-0' />", "VALIDAÇÃO F.T.");
            if (data.datetime_transmission_validated) {
              step.tooltip.push(
                "Finalizado",
                this.dateFormatter(data.datetime_transmission_validated)
              );
            } else if (data.datetime_transmission_uploaded) {
              step.tooltip.push(
                `Em Progresso: ${this.clockRunning(
                  data,
                  "datetime_transmission_uploaded"
                )}`
              );
              this.possibleValidationFT = true;
              this.uploadControlTransmissionID = data.recordings_upload_control_transmission_id;
            }
          }
        }
      });
    },
    dateFormatter(date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY - HH:mm:ss");
      } else {
        return "--";
      }
    },
    dateUTCFormatter(date) {
      if (date) {
        return moment(date)
          .utc(true)
          .tz(this.tzString)
          .format("DD/MM/YYYY - HH:mm:ss");
      } else {
        return "--";
      }
    },
    clockRunning(data, key) {
      let currentTime = new Date();
      let timeBegan = new Date(data[key]);
      let timeElapsed = Math.abs(currentTime.getTime() - timeBegan.getTime());
      let h, m;
      h = Math.floor(timeElapsed / 1000 / 60 / 60);
      m = Math.floor((timeElapsed / 1000 / 60 / 60 - h) * 60);

      let result = this.zeroPrefix(h) + ":" + this.zeroPrefix(m) + "hs";
      return result;
    },
    zeroPrefix(num) {
      let value = num + "";
      if (value.length > 1) return num;
      return "0" + num;
    },
    appointmentOnHold(date) {
      let appointmentStart = new Date(date);
      let currentDate = new Date();
      return currentDate > appointmentStart;
    },
    activeButton(item) {
      if (
        this.validateAction &&
        item.tooltip[0] == "validação" &&
        (this.possibleValidation || this.possibleValidationFT)
      ) {
        return true;
      }
      return false;
    },
    isFT(data) {
      return Object.keys(data).some((k) => k == "edition_transmission_id");
    },
    showBadge(item) {
      let valid = ["edição", "upload", "validação"];
      if (this.isActiveFT && valid.includes(item.tooltip[0])) {
        return true;
      }
      return false;
    },
    getColor(item) {
      if (
        this.possibleValidationFT &&
        item.tooltip[0] == "validação"
      ) return "info";

      if (item && item.status_name) {
        const expr = item.status_name.toLowerCase();
        switch (expr) {
          case "finalizado":
            return "success";
          case "cancelado":
            return "danger";
          case "pendente":
            return "primary";
          default:
            return "warning";
        }
      }
    },
  },
};
</script>

<style>
.b-avatar .b-avatar-badge {
  border-color: white;
  border-width: 1px;
  border-style: solid;
}
.bar {
  z-index: 1;
  display: block;
  width: 10px;
  height: 3px;
}
.circle {
  z-index: 2;
}
.process {
  display: inline-flex;
  align-items: center;
}
.success {
  background-color: #28c76e;
}
.warning {
  background-color: #f5912c;
}
.danger {
  background-color: #ea5455;
}
.primary {
  background-color: #9aa1b0;
}
.info {
  background-color: #1990ff;
}
</style>
